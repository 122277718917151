import './js/scaleRoot.2.js';
import './js/scrollFrame.2.js';
import './js/scrollwatch.js';
// import './js/_language.js';
import './js/setup.js';
import './scss/main.scss';
import lozad from 'lozad';
const observer = lozad('.lazyload', {
  loaded: function(el) {
    el.classList.add('is-loaded');
  }
});
observer.observe();
