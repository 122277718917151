// import 'animate.css';
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

import './scss/block_4.scss';

window.addEventListener("load", function () {
  var swiper = new Swiper(".mySwiper", {
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: function (index, className) {
        index = parseInt(index) + 1;
        const textWeek = $('.week-' + index).data('text');
        return `
          <span class="swiper-pagination-bullet">${textWeek}</span>
        `;
      }
    },
    autoplay: {
      delay: 5000,
    },
  });
});