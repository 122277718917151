// Scale
window.addEventListener("load", function(){
  Array.from($("#wrapper,.outer, .banner main")).forEach(function(item) {
    item.classList.add("scaleDesktop", "scaleMobile");
  });
  $("#wrapper").scalePlatform();
  $(window).on("resize", function(){
      $("#wrapper").scalePlatform();
  });
});
// Navbar Mobile
$( "#mobile-menu" ).click(function(event) {
  event.preventDefault()
  $(".banner__navbar").toggleClass("active");
});

// Target click
var target;
$(".btn-openpopup, .banner__right-arrow, .banner__right-arrow.active, .clickActive").on("click", function (e) {
  target = $(this).data("target");
  $(target).toggleClass("active");
  e.preventDefault();
  return false;
});
if ($(".popup .background").length > 0) {
  $(".popup .background,.close-popup2").each(function () {
    $(this).on("click", function () {
      $(this).parent().removeClass("active");
    });
  });
}
$("body").on("click", ".close-popup", function (event) {
  //$(".popup").removeClass("active");
  // $(this).parent().parent().removeClass("active");
  $(this).parents('.popup').removeClass("active");
});

// document.querySelector('.btn-nolayer').addEventListener("click", function(e) {
//   document.querySelector('#popup_reward').classList.remove("active");
// });

setTimeout(function() {
    $(".scrollwatch-pin").each(function(){
      var elementId = "#" + $(this).attr("id");
      var elParentId = $(this).parents('section').attr("id");
      $().scrollWatch({
          options: {
              watchOnce: false,
              watch: elementId,
              scrollThrottle: 0,
              onElementInView: function () {
                // console.log(this);
                //   $('.scrollFrameControl').removeClass("active");
                //   $('.scrollFrameControl[href="#'+elParentId+'"]').addClass("active");
                //   var theChildren = $(elementId).parents(".section").find(".animate__onview");
                //   theChildren.each(function(){
                //       var elementAnimate = $(this).attr("data-animate");
                //       $(this).addClass("animate__animated");
                //       $(this).addClass(elementAnimate);
                //   })
              },
              onElementOutOfView: function () {
                  var theChildren = $(elementId).parents(".section").find(".animate__onview");
                  theChildren.each(function(){
                      var elementAnimate = $(this).attr("data-animate");
                      // $(this).removeClass("animate__animated");
                      // $(this).removeClass(elementAnimate);
                  });
              }
          }
      });
  });
}, 300);

// Locate
// var locate = document.querySelector('body').classList[0];
// var url = `./assets/${locate}`;
// // var url = `https://global-mainsite.mto.zing.vn/products/metalslug/landing/2023-muachung/dist/assets/images/locate/${locate}`;
// Array.from(document.querySelectorAll('img')).forEach(function(item) {
//   if (item.dataset.js) {
//     item.src = `${url}/${item.dataset.js}`
//   };
// });