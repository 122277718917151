// import 'animate.css';
// import Swiper, {Navigation} from 'swiper';
// import 'swiper/css';
// import 'swiper/css/navigation'
// import styles bundle
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import './scss/block_5.scss';

import '../main/js/_vportal.js';

window.addEventListener("load", function(){
	var swiper2 = new Swiper(".mySwiper2", {
		slidesPerView: 1,
		loop: true,
		breakpoints: {
	    769: {
	      slidesPerView: 3,
	      watchSlidesProgress: true,
	      loop: false,
	      // centeredSlides: true
	    }
	  }
	});

	$(".news").each(function () {
        var postsTag = "#" + $(this).attr("id");
        console.log(postsTag);
        $().vPortal({
            container: postsTag,
            el: {
                tabContainer: '.news_tab > ul',
                resultContainer: '.news_list',
            },
            paginationOptions: {
            }
        });

    });
	
});