// import 'animate.css';
// import Swiper, {Navigation} from 'swiper';
// import 'swiper/css';
// import 'swiper/css/navigation';
import './scss/block_6.scss';

window.addEventListener("load", function(){
	$('#block_6 .btn.online').on("click", function() {
	  $('#block_6 .btn').removeClass('active');
	  $('#block_6 .box').removeClass('active');
	  $('#block_6 .box.online').addClass('active');
	  $(this).addClass('active');
	  return false;
	});
	$('#block_6 .btn.offline').on("click", function() {
	  $('#block_6 .btn').removeClass('active');
	  $('#block_6 .box').removeClass('active');
	  $('#block_6 .box.offline').addClass('active');
	  $(this).addClass('active');
	  return false;
	});

	$('#block_6 .box .text').on("click", function() {
		if ($(this).parent().hasClass('active')) {
			$(this).parent().toggleClass('active');
		} else {
			$('#block_6 .box ul li').removeClass('active');
			$(this).parent().toggleClass('active');
		}
	  
	  
	});
});